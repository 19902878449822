//TODO: change
export const FACTORY_ADDRESS = '0xF38E7c7f8eA779e8A193B61f9155E6650CbAE095'

export const FACTORY_ADDRESS_V3 = '0xC5BfA92f27dF36d268422EE314a1387bB5ffB06A'

export const WETH = '0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  THREE_MONTHS: '3 months',
  YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = ['https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json']

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0xfe9762cec72920ca0f93f5b58d20a50be0602d59',
  '0x71a2fc843797bfb60bd757f08f87f7babba0abce',
  '0xfaca2b369724e73cf29f0f60d9b0db579af006df',
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0x1804bc3e2c188c1e2e4d62500edccc71451f7436',
  '0xdbc79d3f46c80a70999642ab14d36a536cca8452',
  '0x913d0d99ef33a8dfcfb97cd01e5facc8ed0fd4e9',
  '0xdc4dff04b277b0830ef7260dab9383ae4abd9615',
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = [
  '0x26519b547416e4f53f3a4b05b95ef859c3bd89fe',
  '0xf6c7197eaff13002a7b2a2e835a7bb6969a4b026',
  '0x46622913ce40c54ec14857f72968d4baaf963947',
  '0x0f8d6953f58c0dd38077495aca64cbd1c76b7501',
]
