import React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle } from 'styled-components'
import { useDarkModeManager } from '../contexts/LocalStorage'
import styled from 'styled-components'
import { Text } from 'rebass'

export default function ThemeProvider({ children }) {
  const [darkMode] = useDarkModeManager()

  return <StyledComponentsThemeProvider theme={theme(darkMode)}>{children}</StyledComponentsThemeProvider>
}

const theme = (darkMode, color) => ({
  customColor: color,
  textColor: darkMode ? color : 'black',

  panelColor: darkMode ? '#161616' : '#F2F4F8',
  backgroundColor: darkMode ? '#161616' : '#F2F4F8',

  uniswapPink: darkMode ? '#51C0E1' : '#31415E',

  concreteGray: darkMode ? '#29C22F' : '#F2F4F8',
  inputBackground: darkMode ? '#161616' : '#E4E9F1',
  shadowColor: darkMode ? '#161616' : '#dea74b',
  mercuryGray: darkMode ? '#161616' : '#BCC8DC',

  text1: darkMode ? 'rgba(255, 255, 255, 0.87)' : '#1F1F1F',
  text2: darkMode ? 'rgba(255, 255, 255, 0.87)' : '#565A69',
  text3: darkMode ? 'rgba(255, 255, 255, 0.6)' : '#888D9B',
  text4: darkMode ? 'rgba(255, 255, 255, 0.38)' : '#BCC8DC',
  text5: darkMode ? 'rgba(255, 255, 255, 0.38)' : '#D7DEEA',

  // special case text types
  white: '#FFFFFF',

  // backgrounds / greys
  bg1: darkMode ? '#161616' : '#F2F4F8',
  bg2: darkMode ? 'rgba(255, 255, 255, 0.05)' : '#E4E9F1',
  bg3: darkMode ? 'rgba(50, 46, 45, 0.8)' : '#D7DEEA',
  bg4: darkMode ? 'rgba(255, 255, 255, 0.12)' : '#BCC8DC',
  bg5: darkMode ? 'rgba(255, 255, 255, 0.14)' : '#A1B2CE',
  bg6: darkMode ? '#161616' : '#FFFFFF',

  //specialty colors
  modalBG: darkMode ? 'rgba(18, 17, 34, 0.75)' : 'rgba(18, 17, 34, 0.6)',
  advancedBG: darkMode ? 'rgba(22,22,22,.5)' : 'rgba(255, 255, 255, 0.4)',
  onlyLight: darkMode ? '#161616' : 'transparent',
  divider: darkMode ? '#4A4C70' : 'rgba(49, 65, 94, 0.4)',

  //primary colors
  primary1: darkMode ? '#dea74b' : '#dea74b',
  primary2: darkMode ? '#dea74b' : '#dea74b',
  primary3: darkMode ? '#ADADEB' : '#ADADEB',
  primary4: darkMode ? 'rgba(50, 46, 45, 0.8)' : '#2C2CB5',
  primary5: darkMode ? 'rgba(22, 22, 22, 0.8)' : '#242494',

  // color text
  primaryText1: darkMode ? '#6665DD' : '#6665DD',

  // secondary colors
  secondary1: darkMode ? '#77B2FC' : '#dea74b',
  secondary2: darkMode ? '#dea74b' : '#BAE6F3',
  secondary3: darkMode ? '#83BAFC' : '#DCF2F9',

  shadow1: darkMode ? '#DCF2F9' : '#31415E',

  // other
  red1: '#FF6978',
  green1: '#CFD784',
  yellow1: '#FFE600',
  yellow2: '#FFA770',
  link: '#dea74b',
  blue: '#77B2FC',

  background: darkMode
    ? 'radial-gradient(135.95% 135.95% at 50% -35.95%, rgba(102, 102, 255, 0.5) 0%, rgba(18, 17, 34, 0) 100%)'
    : '#F2F4F8',
})

const TextWrapper = styled(Text)`
  color: ${({ color, theme }) => theme[color]};
`

export const TYPE = {
  main(props) {
    return <TextWrapper fontWeight={500} fontSize={14} color={'text1'} {...props} />
  },

  body(props) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'text1'} {...props} />
  },

  small(props) {
    return <TextWrapper fontWeight={500} fontSize={11} color={'text1'} {...props} />
  },

  header(props) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },

  largeHeader(props) {
    return <TextWrapper fontWeight={500} color={'text1'} fontSize={24} {...props} />
  },

  light(props) {
    return <TextWrapper fontWeight={400} color={'text3'} fontSize={14} {...props} />
  },

  pink(props) {
    return <TextWrapper fontWeight={props.faded ? 400 : 600} color={props.faded ? 'text1' : 'text1'} {...props} />
  },
}

export const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: none;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`

export const ThemedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  //background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  position: absolute;
  top: 0px;
  left: 0px;
  /* z-index: ; */

  transform: translateY(-110vh);
`

export const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;    
    background-color: ${({ theme }) => theme.bg6};
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: none
    }
  }

  
.three-line-legend {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: #20262E;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: white;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .three-line-legend {
    display: none !important;
  }
}

.tv-lightweight-charts{
  width: 100% !important;
  

  & > * {
    width: 100% !important;
  }
}


  html {
    font-size: 1rem;
    font-variant: none;
    color: 'black';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
  }
`
