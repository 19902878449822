import React, { createContext, useContext, useState, useEffect } from 'react'

const TokenListContext = createContext()

const TokenListProvider = ({ children }) => {
  const [tokenList, setTokenList] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://token-list.hercules.exchange/tokens.json')
        if (!response.ok) {
          throw new Error('Failed to fetch token list')
        }
        const data = await response.json()
        setTokenList(data)
      } catch (error) {
        console.error('Error fetching token list:', error)
      }
    }

    if (!tokenList) {
      fetchData()
    }
  }, [tokenList])

  return <TokenListContext.Provider value={{ tokenList }}>{children}</TokenListContext.Provider>
}

export const useTokenListContext = () => useContext(TokenListContext)

export const useTokenList = () => {
  const { tokenList } = useContext(TokenListContext)
  return tokenList
}

export default TokenListProvider
