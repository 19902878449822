import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import axios from 'axios'

// late.
export const clientV2 = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/camelotlabs/camelot-amm',
    // uri: 'https://api.thegraph.com/subgraphs/name/0xpercival/camelot-amm-test-2',
    // uri:'https://gateway-arbitrum.network.thegraph.com/api/44e29639aebbb35310b5c9bf9c8ae679/subgraphs/id/8zagLSufxk5cVhzkzai3tyABwJh53zxn9tmUYJcJxijG',
    uri: 'https://metisapi.0xgraph.xyz/subgraphs/name/amm-subgraph-andromeda/',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientV3 = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/camelotlabs/camelot-amm-v3-2',
    uri: 'https://metisapi.0xgraph.xyz/subgraphs/name/cryptoalgebra/analytics',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthRESTClient = axios.create({
  baseURL:
    'https://metisapi.0xgraph.xyz/api/public/query_deployment?subgraph_id=Qma3mC8oEdtbdhuLrc3XNgqenzyG61vwxYZPA4ChgUpp4P',
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),

  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-blocks',
    uri: 'https://metisapi.0xgraph.xyz/subgraphs/name/blocks-metis',
  }),
  cache: new InMemoryCache(),
})
